import React from 'react';
import logo from '../assets/images/qxp-logo.svg';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'gatsby';
import Nav from 'react-bootstrap/Nav';

export default function Header() {
	return (
		<section id="top-header" className="header constrain-1500w">


				<Navbar expand="md">

					<Navbar.Brand>
						<Link to="/"><img src={logo} alt="Logo" className="header-logo"/></Link>
					</Navbar.Brand>



						<Navbar.Toggle/>
						<Navbar.Collapse>
							<Nav className="ml-auto">
								<Nav.Link to="/" as={Link}>Home</Nav.Link>
								<Nav.Link to="/faq" as={Link}>FAQ</Nav.Link>
								<Nav.Link to="/contact" as={Link}>Contact</Nav.Link>
							</Nav>
						</Navbar.Collapse>


				</Navbar>


		</section>
	)
		;
};
