import {useStaticQuery, graphql} from 'gatsby';

export const useSiteData = () => {
	const {contactUs} = useStaticQuery(graphql`
		query Global {
			contactUs {
				BodyText
				Email
				Phone
				Address
				ABN
			}
		}
    `);

	return {contactUs};
};
