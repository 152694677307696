import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useSiteData} from '../hooks/useSiteData';

export default function Footer() {
	const {contactUs} = useSiteData();

	return (
		<Row>
			<Col className="text-center" md={6}>
				&#169; QXP {new Date().getFullYear()}
			</Col>
			<Col className="text-center" md={6}>
				<a href={`mailto:${contactUs.Email}`}>{contactUs.Email}</a>
			</Col>
		</Row>
	);
}
