import React from 'react';
import {Helmet} from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import '../app.css';
import Header from './Header';
import Footer from './Footer';

export default function Page({children}) {
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8"/>

				<title>qxpCMS</title>
				<meta name="viewport" content="width=device-width, user-scalable=yes, initial-scale=1.0, minimum-scale=1.0"/>

				<meta http-equiv="X-UA-Compatible" content="ie=edge"/>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
				<link rel="manifest" href="/site.webmanifest"/>
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
				<meta name="msapplication-TileColor" content="#da532c"/>
				<meta name="theme-color" content="#ffffff"/>
				<link rel="preconnect" href="https://fonts.googleapis.com/" crossOrigin />
				<link rel="dns-prefetch" href="https://fonts.googleapis.com/" />
				<link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
				<link rel="dns-prefetch" href="https://fonts.gstatic.com/" />
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "Corporation",
						"name": "qxp",
						"url": "https://www.qxp.com.au",
						"logo": "",
						"contactPoint": {
						"@type": "ContactPoint",
						"telephone": "+61740982868",
						"contactType": "technical support",
						"areaServed": "AU",
						"availableLanguage": "en"
					}
					})}
				</script>

				<script async src="https://www.googletagmanager.com/gtag/js?id=G-D9ZVLWHCDT"></script>
				<script>
					{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', 'G-D9ZVLWHCDT');
					`}
				</script>
			</Helmet>

			<div className="container-nobs-fluid">
				<div className="header">
					<Header />
				</div>
				<div id="site-body" className="constrain-1500w">
					<div className="content">
						{children}
					</div>
				</div>
				<footer className="footer constrain-1500w">
					<Footer />
				</footer>
			</div>
		</div>
	);
}
